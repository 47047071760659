@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

$large-width: 750px;
$small-width: 440px;

.anonymous-commenter-modal {
  .modal {
    max-width: $small-width;
    width: $large-width;
  }

  &__body {
    width: 360px;

    &__input-container {
      margin-bottom: 40px;
    }
  }

  &__button {
    margin-bottom: 20px;
    margin-top: 30px;
    width: 100%;
  }

  @media only screen and (max-width : $media-sizes-small) {
    &__body {
      box-sizing: border-box;
      width: 100%;
    }

    .modal {
      max-width: 100vw !important;
      padding: 30px 20px;

      &__header {
        &__text {
          .text {
            white-space: normal !important;
          }

          h3 {
            font-size: 20px;
          }
        }
      }
    }
  }

  &__or-text {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: -15px;
    margin-top: -15px;
    text-align: center;
  }
}
