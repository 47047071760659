@import '~@shootsta/common-react/dist/ui-kit/scss/main';

.root {
  display: flex;
  flex-direction: column;
}

.sentry-error-embed {
  p,
  h2 {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    letter-spacing: 1px;
  }
}

.grecaptcha-badge { 
  visibility: hidden;
}
