@import '~@shootsta/common-react/dist/ui-kit/scss/core/vars';

.reset-password {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 10px;

  > .closable-page {
    &__content {
      max-width: 600px;
      width: 100%;
    }
  }

  &__header {
    color: $black;
    margin-bottom: 45px;
    text-align: center;

    > .icon-container {
      margin-bottom: 25px;
    }

    > h1 {
      margin-bottom: 0;
    }

    > h2 {
      font-size: $font-sizes-large;
      font-weight: $font-weights-regular;
      margin-bottom: 0;
    }
  }
}
